import { ChangeEvent } from 'react'
import useCreateFavorite from './hooks/useCreateFavorite'
import {
  FavoriteFieldLabel,
  CreateFavoriteText,
  PopoverContainer,
  PopoverContent,
  FavoriteFieldText,
  SaveLayoutGroup,
  CancelButton,
  ConfirmButton,
} from './styles'
import { CameraMap } from '../../pages/WorkZone/hooks/useWorkzone'

interface CreateFavoriteProps {
  lanId: string
  onCloseCreateFavorite: Function
  selectedCameras: CameraMap
}
const CreateFavorite = ({
  lanId,
  onCloseCreateFavorite,
  selectedCameras,
}: CreateFavoriteProps) => {
  const { layoutName, setLayoutName, makeSaveLayoutRequest } =
    useCreateFavorite(lanId)
  return (
    <PopoverContainer>
      <PopoverContent>
        <h1>Save Favorite</h1>
        {/*
            KEEP THIS, WILL LIKELY NEED IN FUTURE
          */}
        {/* <LayoutToggleGroup>
          <LayoutToggleButton type="primary" fullWidth>
            Save New Layout
          </LayoutToggleButton>
          <LayoutToggleButton type="secondary" fullWidth>
            Edit Existing Layout
          </LayoutToggleButton>
        </LayoutToggleGroup> */}
        <div>
          <FavoriteFieldLabel>Favorite Name</FavoriteFieldLabel>
          <CreateFavoriteText
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setLayoutName(event.target.value)
            }
            id="favorite-name-input"
            value={layoutName}
          />
        </div>
        <div>
          <FavoriteFieldLabel>Created By</FavoriteFieldLabel>
          <FavoriteFieldText>{lanId}</FavoriteFieldText>
        </div>
        <SaveLayoutGroup>
          <CancelButton onClick={onCloseCreateFavorite} type="ghost">
            Cancel
          </CancelButton>
          <ConfirmButton
            disabled={!layoutName}
            onClick={() => {
              makeSaveLayoutRequest(layoutName, selectedCameras)
              onCloseCreateFavorite()
            }}
            type="primary"
          >
            Save Favorite
          </ConfirmButton>
        </SaveLayoutGroup>
      </PopoverContent>
    </PopoverContainer>
  )
}

export default CreateFavorite
