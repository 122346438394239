import EmptyState from './pngs/EmptyState.svg'
import { MessageContainer, MessageText } from './styles'

interface NothingToShowProps {
  text: string
}

const NothingToShowMessage = ({ text }: NothingToShowProps) => {
  return (
    <MessageContainer>
      <EmptyState />
      <MessageText>{text}</MessageText>
    </MessageContainer>
  )
}

export default NothingToShowMessage
