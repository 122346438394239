import { useQuery } from 'react-query'
import axios from 'axios'
import QueryDefaults from '../defaults'

export default function useAccessQuery(
  apiURL?: string | undefined,
  userId?: string | undefined,
  userEmail?: string | undefined,
  isAdmin: boolean = false,
  fetchOnLoad: boolean = QueryDefaults.FETCH_ON_LOAD,
  staleTime: number = QueryDefaults.STALE_TIME,
  cacheTime: number = QueryDefaults.CACHE_TIME,
) {
  const fetchData = async () => {
    const response = await (isAdmin
      ? axios.get(`${apiURL}/pending_request?lan_id=${userId}`)
      : userEmail
        ? axios.get(
            `${apiURL}/pending_request?approver=${userEmail?.toLowerCase()}`,
          )
        : axios.get(`${apiURL}/pending_request?lan_id=${userId}`))
    if (!response.data) {
      throw new Error('Data not found')
    }
    return response.data
  }

  return useQuery<any>(['locationAccess'], fetchData, {
    enabled: fetchOnLoad,
    staleTime: staleTime,
    cacheTime: cacheTime,
  })
}
