import { Button } from '@enterprise-ui/canvas-ui-react'
import styled from 'styled-components'
import './ModeSelectBar.css'

export const MODE_BUTTON_HEIGHT = 80
export const ModeButtonContainer = styled.footer`
  display: flex;
  height: ${MODE_BUTTON_HEIGHT}px;
  .C-ButtonGroup {
    flex-grow: 1;
  }

  .C-ButtonGroup__ListItem {
    flex-grow: 1;
  }

  .C-Button {
    padding: 0;
    border-radius: 0px !important;
  }
`

export const ModeButton = styled(Button)`
  background: #272727 !important;
  border: none;
  color: #c4c4c4;
  font-size: 12px;
  flex-direction: column;
  gap: 2px;

  &.\--type-primary {
    svg {
      color: #76b7f7;
    }
    color: #fff;
  }
`
