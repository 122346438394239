import { styled } from 'styled-components'
import { Button } from '@enterprise-ui/canvas-ui-react'

export const ProfileContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  padding: 10px 22px 0;
`

export const UserAvatarContainer = styled.div`
  align-items: center;
  background-color: #272727;
  border-radius: 50%;
  display: flex;
  height: 140px;
  justify-content: center;
  width: 140px;
`

export const UserInitials = styled.p`
  color: white;
  font-size: 35px;
`

export const UserPreferredName = styled.p`
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
`

export const ProfileActionContainer = styled.div`
  background-color: #272727;
  border-radius: 20px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const ProfileActionItem = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.06);
  }
  align-items: center;
  display: flex;
  gap: 10px;
  height: 71px;
  padding-left: 10px;
`

export const ProfileActionButton = styled(Button)`
  border-radius: 8px !important;
  background-color: #34383c;
  border-color: transparent !important;
  color: #bdbdbd;
`
