import VideoCard from '../../components/VideoCard'
import { CardContainer } from '../../components/CardGrid/styles'
import { CameraMap } from '../../pages/WorkZone/hooks/useWorkzone'
import CameraTreeItem from 'trueye-component-library/dist/models/camera/CameraTreeItem'
import { MessageContainer } from '../../components/NothingToShowMessage/styles'
import MonitorIcon from './icons/Monitor.svg'
import CreateFavorite from '../CreateFavorite'

interface MonitorProps {
  lanId: string
  authToken: string
  removeCamera: Function
  selectedCameras: CameraMap
  sessionId: string
  onLogoClick: Function
  createFavoriteOpen: boolean
  onCloseCreateFavorite: Function
}

const Monitor = ({
  authToken,
  removeCamera,
  selectedCameras,
  sessionId,
  lanId,
  onLogoClick,
  createFavoriteOpen,
  onCloseCreateFavorite,
}: MonitorProps) => {
  if (selectedCameras.size) {
    return (
      <div>
        {createFavoriteOpen && (
          <CreateFavorite
            lanId={lanId}
            onCloseCreateFavorite={onCloseCreateFavorite}
            selectedCameras={selectedCameras}
          />
        )}
        <CardContainer>
          {[...selectedCameras.values()].map((camera: CameraTreeItem) => {
            return (
              <VideoCard
                key={camera.id}
                lanId={lanId}
                authToken={authToken}
                cameraData={camera}
                removeCamera={removeCamera}
                sessionId={sessionId}
              />
            )
          })}
        </CardContainer>
      </div>
    )
  } else {
    return (
      <MessageContainer>
        <MonitorIcon onClick={() => onLogoClick()} />
      </MessageContainer>
    )
  }
}

export default Monitor
