import EnterpriseIcon, { CheckIcon } from '@enterprise-ui/icons'
import {
  CameraInfoTextBox,
  CameraItemContainer,
  CheckIconContainer,
} from './styles'

interface CameraItemProps {
  cameraName: string
  onCameraClick: Function
  isChecked: boolean
}

export const CameraItem = ({
  cameraName,
  onCameraClick,
  isChecked,
}: CameraItemProps) => {
  return (
    <CameraItemContainer onClick={() => onCameraClick()}>
      <CheckIconContainer>
        {isChecked && <EnterpriseIcon icon={CheckIcon} />}
      </CheckIconContainer>
      <CameraInfoTextBox>{cameraName}</CameraInfoTextBox>
    </CameraItemContainer>
  )
}
