import { styled } from 'styled-components'

export const RBACBox = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 289px;
  max-width: 360px;
  width: 100%;
  padding: 6px 8px;
  border: 0.5px;
  border-color: #6b6b6b;
  border-style: solid;
  background: #1a1a1a;
}
`

export const Pair = styled.div`
  display: grid;
  grid-template-columns: 1.6fr 2fr;
  padding: 6px 8px;
  font-size: 12px;
`

export const Heading = styled.div`
  color: #a1a1a1;
`

export const Value = styled.div`
  color: white;
`

export const Buttons = styled.div`
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin-top: auto;
  margin-bottom: 8px;
  margin-left: 6px;
  margin-right: 6px;
`

export const Reject = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  background-color: #f8a3a0;
  border-radius: 5px;
  color: #000000;
  padding: 8px 35px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;

  &:active {
    // background-color: #76b7f7;
    background-color: #ff7a7a;
    box-shadow: 0px 0px 2px 1px #ff7a7a;
  }

  @media (max-width: 370px) {
    padding: 8px 25px;
  }
`

export const Approve = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  background-color: #5def79;
  border-radius: 5px;
  color: #000000;
  padding: 8px 30px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;

  &:active {
    background-color: #3ce65b;
    box-shadow: 0px 0px 2px 1px #3ce65b;
  }

  @media (max-width: 370px) {
    padding: 8px 23px;
  }
`
