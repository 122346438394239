import ModeSelectBar from '../../components/ModeSelectBar'
import { FeatureSpace } from './styles'
import CameraDrawer from '../../components/CameraDrawer'
import Monitor from '../../pages/Monitor'
import RBAC from '../../pages/RBAC'
import Profile from '../../pages/Profile'
import useWorkZone, { CameraMap } from './hooks/useWorkzone'
import { AppMode, MONITOR_MODE, PROFILE_MODE, RBAC_MODE } from '../../useMyTvs'
import { ToastProvider } from '@enterprise-ui/canvas-ui-react'
import { Layout } from 'trueye-component-library'

interface WorkZoneProps {
  cameraDrawerOpen: boolean
  currentMode: AppMode
  lanId: string
  onLogout: Function
  setCameraDrawerOpen: Function
  setCurrentMode: Function
  sessionId: string
  authToken: string
  userLegalName: string
  createFavoriteOpen: boolean
  onCloseCreateFavorite: Function
  setVideoIsPlaying: Function
  hideAccess: boolean
  userEmail: string
  isAdmin: boolean
  addCameras: Function
  removeCamera: Function
  selectedCameras: CameraMap
  playFavoriteOnGrid: Function
  clearFavorite: Function
  currentPlayingFavorite: Layout | null
}

const WorkZone = ({
  cameraDrawerOpen,
  currentMode,
  lanId,
  onLogout,
  setCameraDrawerOpen,
  setCurrentMode,
  sessionId,
  authToken,
  userLegalName,
  createFavoriteOpen,
  onCloseCreateFavorite,
  hideAccess,
  userEmail,
  isAdmin = false,
  addCameras,
  removeCamera,
  selectedCameras,
  playFavoriteOnGrid,
  clearFavorite,
  currentPlayingFavorite,
}: WorkZoneProps) => {
  const { openLocationData, setOpenLocationData } = useWorkZone()

  return (
    <section>
      <ToastProvider location="bottom">
        <CameraDrawer
          drawerIsOpen={cameraDrawerOpen}
          addCameras={(cameraMap: CameraMap) => {
            addCameras(cameraMap)
            setCameraDrawerOpen(false)
          }}
          removeCamera={removeCamera}
          selectedCameras={selectedCameras}
          lanId={lanId}
          onClearFavorite={clearFavorite}
          onShowFavorite={(favorite) => {
            playFavoriteOnGrid(favorite)
            setCameraDrawerOpen(false)
          }}
          currentPlayingLayout={currentPlayingFavorite}
          openLocationData={openLocationData}
          setOpenLocationData={setOpenLocationData}
        />
        <FeatureSpace>
          {currentMode === MONITOR_MODE && (
            <Monitor
              lanId={lanId}
              authToken={authToken}
              removeCamera={removeCamera}
              selectedCameras={selectedCameras}
              sessionId={sessionId}
              onLogoClick={() => setCameraDrawerOpen(true)}
              createFavoriteOpen={createFavoriteOpen}
              onCloseCreateFavorite={onCloseCreateFavorite}
            />
          )}
          {currentMode === RBAC_MODE && (
            <RBAC lanId={lanId} userEmail={userEmail} isAdmin={isAdmin} />
          )}
          {currentMode === PROFILE_MODE && (
            <Profile userLegalName={userLegalName} onLogoutClick={onLogout} />
          )}
        </FeatureSpace>
      </ToastProvider>
      <ModeSelectBar
        currentMode={currentMode}
        setcurrentMode={setCurrentMode}
        hideAccess={hideAccess}
      />
    </section>
  )
}

export default WorkZone
