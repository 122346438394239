import { useEffect, useState } from 'react'
import { Layout, useLayoutQuery } from 'trueye-component-library'
import apiConfig from '../../../apiConfig'
import axios from 'axios'
import { ToasterToastProps, useToaster } from '@enterprise-ui/canvas-ui-react'

export default function useFavorites(
  lanId: string,
  playingLayout: Layout | null,
) {
  const [publicLayouts, setPublicLayouts] = useState<Layout[]>([])
  const [privateLayouts, setPrivateLayouts] = useState<Layout[]>([])

  const [waitlistedLayout, setWaitlistedLayout] = useState<Layout | null>(null)

  const [modalOpen, setModalOpen] = useState(false)
  const [deleteLayoutId, setDeleteLayoutId] = useState<string | null>(null)

  const makeToast = useToaster()
  const toastSuccessConfig = {
    className: 'access-toaster',
    type: 'success',
    heading: 'Success',
  }

  const toastErrorConfig = {
    className: 'access-toaster',
    type: 'error',
    heading: 'Something went wrong',
    message: 'Please try again',
  }

  const {
    data = [],
    isFetching,
    isError,
    isSuccess,
    refetch,
  } = useLayoutQuery(
    apiConfig.camera?.layout || '',
    lanId.toUpperCase(),
    'myTvs',
  ) // TODO make app name a const

  useEffect(() => {
    const newPublicLayouts: Layout[] = []
    const newPrivateLayouts: Layout[] = []
    data.forEach((layout: Layout) => {
      if (layout.isPublic) {
        newPublicLayouts.push(layout)
      } else {
        newPrivateLayouts.push(layout)
      }
    })
    setPublicLayouts(newPublicLayouts)
    setPrivateLayouts(newPrivateLayouts)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching])

  useEffect(() => {
    setWaitlistedLayout(playingLayout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playingLayout])

  const deleteAFavoriteQuery = (id: string) => {
    return axios.delete(`${apiConfig.camera?.layout}`, { data: { id } })
  }

  const deleteFavorite = async () => {
    if (!deleteLayoutId) {
      return
    }
    deleteAFavoriteQuery(deleteLayoutId)
      .then(() => {
        makeToast({
          ...toastSuccessConfig,
          message: 'Favorite deleted',
        } as ToasterToastProps)
        setModalOpen(false)
        setDeleteLayoutId(null)
        refetch()
      })
      .catch(() => {
        makeToast({
          ...toastErrorConfig,
          message: 'Could not delete favorite',
        } as ToasterToastProps)
        setModalOpen(false)
        setDeleteLayoutId(null)
      })
  }

  const promptDelete = (id: string) => {
    setModalOpen(true)
    setDeleteLayoutId(id)
  }

  const cancelDelete = () => {
    setModalOpen(false)
    setDeleteLayoutId(null)
  }

  return {
    isError,
    isFetching,
    isSuccess,
    privateLayouts,
    publicLayouts,
    waitlistedLayout,
    setWaitlistedLayout,
    modalOpen,
    promptDelete,
    cancelDelete,
    deleteFavorite,
  }
}
