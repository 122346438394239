import styled from 'styled-components'

export const CameraItemContainer = styled.div`
  align-items: center;
  background: #34383c;
  display: flex;
  gap: 16px;
  height: 48px;
  padding-left: 25px;
`

export const CameraInfoTextBox = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  color: #bdbdbd;
`

export const CheckIconContainer = styled.div`
  min-width: 20px;
  color: #90caf9;
`
