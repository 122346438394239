import { ModeButtonContainer, ModeButton } from './styles'
import { ButtonGroup } from '@enterprise-ui/canvas-ui-react'
import MonitorIcon from './icons/Monitor.svg'
import MonitorIconFocused from './icons/MonitorSelected.svg'
import AccessIcon from './icons/AccessControl.svg'
import AccessIconFocused from './icons/AccessControlSelected.svg'
import ProfileIcon from './icons/User.svg'
import ProfileIconFocused from './icons/UserFocused.svg'

import { AppMode, MONITOR_MODE, PROFILE_MODE, RBAC_MODE } from '../../useMyTvs'

interface ModeSelectBarProps {
  currentMode: AppMode // TODO Add enum or some kind of constant for this
  setcurrentMode: Function
  hideAccess: boolean
}

const ModeSelectBar = ({
  currentMode,
  setcurrentMode,
  hideAccess,
}: ModeSelectBarProps) => {
  return (
    <ModeButtonContainer>
      <ButtonGroup>
        <ModeButton
          fullWidth
          onClick={() => setcurrentMode(MONITOR_MODE)}
          type={currentMode === MONITOR_MODE ? 'primary' : 'default'}
        >
          {currentMode === MONITOR_MODE ? (
            <MonitorIconFocused />
          ) : (
            <MonitorIcon />
          )}
          {MONITOR_MODE}
        </ModeButton>
        {!hideAccess && (
          <ModeButton
            fullWidth
            onClick={() => setcurrentMode(RBAC_MODE)}
            type={currentMode === RBAC_MODE ? 'primary' : 'default'}
          >
            {currentMode === RBAC_MODE ? <AccessIconFocused /> : <AccessIcon />}
            {RBAC_MODE}
          </ModeButton>
        )}
        <ModeButton
          fullWidth
          onClick={() => setcurrentMode(PROFILE_MODE)}
          type={currentMode === PROFILE_MODE ? 'primary' : 'default'}
        >
          {currentMode === PROFILE_MODE ? (
            <ProfileIconFocused />
          ) : (
            <ProfileIcon />
          )}
          {PROFILE_MODE}
        </ModeButton>
      </ButtonGroup>
    </ModeButtonContainer>
  )
}

export default ModeSelectBar
