import { styled } from 'styled-components'
import { HEADER_HEIGHT } from '../../components/Header/styles'
import {
  CAMERA_ACTION_BUTTON_HEIGHT,
  CAMERA_TREE_TAB_HEIGHT,
} from '../../components/CameraTree/styles'

export const FavoritesContent = styled.div`
  background: #333333;
  height: calc(100dvh - ${HEADER_HEIGHT}px - ${CAMERA_ACTION_BUTTON_HEIGHT}px);
`

export const FavoriteSelectContainer = styled.div`
  height: calc(
    100dvh - ${HEADER_HEIGHT}px - ${CAMERA_TREE_TAB_HEIGHT}px -
      ${CAMERA_ACTION_BUTTON_HEIGHT}px
  );
  overflow: auto;
`

// interface FavoriteItemContainerProps {
//   isSelected: boolean
// }

// export const FavoriteItemContainer = styled.div<FavoriteItemContainerProps>`
//   align-items: center;
//   background: #222222;
//   color: #bdbdbd;
//   display: flex;
//   gap: 12px;
//   height: 48px;
//   padding-left: 42px;

//   ${(props) => {
//     if (props.isSelected) {
//       return `
//         border-left: 6px solid #42a5f5;
//         background: #293038;
//       `
//     }
//   }}
// `
// export const FavoriteIconContainer = styled.div`
//   min-width: 20px;
// `

// export const FavoriteItemTextBox = styled.div`
//   display: flex;
//   flex-grow: 1;
//   flex-direction: column;
// `
