import { CameraFolderHeader } from './styles'
import EnterpriseIcon, { CaretDownIcon } from '@enterprise-ui/icons'
import { FolderIcon, CaretUpIcon } from '@enterprise-ui/icons'
import { FolderTextBox } from './styles'
import { CameraItem } from '../CameraItem'
import { useState } from 'react'
import CameraTreeItem from 'trueye-component-library/dist/models/camera/CameraTreeItem'
import { CameraMap } from '../../pages/WorkZone/hooks/useWorkzone'
import { findInSearchPath } from 'trueye-component-library'

interface CameraFolderProps {
  cameraWaitlist: CameraMap
  folderData: CameraTreeItem // should type this to match what's available to us in a folder object
  onCameraClick: Function
  searchInput: string
}

export const CameraFolder = ({
  cameraWaitlist,
  folderData,
  onCameraClick,
  searchInput,
}: CameraFolderProps) => {
  const [folderOpen, setFolderOpen] = useState<boolean>(false)

  return (
    <div>
      <CameraFolderHeader
        $isOpen={folderOpen}
        onClick={() => {
          setFolderOpen((currentState) => !currentState)
        }}
      >
        <EnterpriseIcon icon={FolderIcon} />
        <FolderTextBox>{folderData.name}</FolderTextBox>
        <EnterpriseIcon icon={folderOpen ? CaretUpIcon : CaretDownIcon} />
      </CameraFolderHeader>
      {(!!searchInput || folderOpen) &&
        folderData?.children &&
        folderData.children.map((child: CameraTreeItem) => {
          const isSearchingForCamera = !!searchInput
          const isChildVisible = isSearchingForCamera
            ? findInSearchPath(child, searchInput)
            : true
          if (!isChildVisible) {
            return null
          }
          return (
            <CameraItem
              cameraName={child.name}
              isChecked={cameraWaitlist.has(child.id)}
              key={child.id}
              onCameraClick={() => onCameraClick(child)}
            />
          )
        })}
    </div>
  )
}
