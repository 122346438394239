import styled from 'styled-components'

export const MessageContainer = styled.div`
  align-items: center;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 100%;
  justify-content: center;
  font-size: 14px;
  text-align: center;
`

export const MessageText = styled.p`
  color: white;
  max-width: 250px;
`
