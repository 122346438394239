import { useState } from 'react'
import CameraTreeItem from 'trueye-component-library/dist/models/camera/CameraTreeItem'
import Location from 'trueye-component-library/dist/models/location/Location'

export type CameraID = string
export type CameraMap = Map<CameraID, CameraTreeItem>

export default function useWorkZone() {
  const [openLocationData, setOpenLocationData] = useState<Location | null>(
    null,
  )

  return {
    openLocationData,
    setOpenLocationData,
  }
}
