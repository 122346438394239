import { styled } from 'styled-components'

export const CardContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding: 12px 2px;
  flex-wrap: wrap;
  justify-content: space-around;
` // perhaps `gap: 12px;` can vary depending on screen size
