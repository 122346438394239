import apiConfig from '../apiConfig'
import axios from 'axios'

export interface LocationAccessRequest {
  id: number
  la_id: number
  state: string
  end_date_access: string
  approve_mode: boolean
}

export const getLocationAccessRequests = (user_id: any) => {
  return axios.get(
    `${apiConfig?.rbac?.locationAccess}/pending_request/?userId=${user_id}`,
    {
      timeout: 5000,
    },
  )
}

export const approveLocationAccessRequest = (la_id: number, state: string) => {
  return axios.put(`${apiConfig?.rbac?.locationAccess}`, {
    id: la_id,
    state,
    approve_mode: true,
  })
}

export const declineLocationAccessRequest = (
  la_id: number,
  state: string,
  declineReason: string,
) => {
  return axios.put(`${apiConfig?.rbac?.locationAccess}`, {
    id: la_id,
    state,
    declination_reason: declineReason,
    approve_mode: true,
  })
}

export const getNumberOfDays = (endDateString: string, currentDate: number) => {
  const [month, day, year] = endDateString.split('/').map(Number)
  const endDate = new Date(year, month - 1, day).getTime()
  return Math.round((endDate - currentDate) / 86400000)
}
