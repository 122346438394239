export type ConnectionStatus = 'CONNECTING' | 'PLAYING' | 'ERROR'

export interface ConnectionDetails {
  url: string
  authToken: string
  cameraId: string
  locationId: string
  imageRef?: React.RefObject<HTMLImageElement>
  onStatusChange?: (status: ConnectionStatus) => void
}

export default class mjpegWebSocket {
  websocket: WebSocket | null
  onStatusChange: ((status: ConnectionStatus) => void) | undefined
  notYetPlaying: boolean = true

  constructor(connectionDetails: ConnectionDetails) {
    // essentially, with the details, we wire up the websocket connection here
    if ('WebSocket' in window) {
      this.websocket = new WebSocket(connectionDetails.url, [
        connectionDetails.authToken.replace('Bearer ', ''),
      ])
      this.websocket.binaryType = 'arraybuffer'
      this.onStatusChange = (statusNumber: ConnectionStatus) => {
        console.log(statusNumber)
      }
      if (connectionDetails.onStatusChange) {
        this.onStatusChange = connectionDetails.onStatusChange
      }

      this.websocket.onopen = function () {
        let streamRequest = {
          cameraId: connectionDetails.cameraId,
          streamType: 'MJPEG',
          stream_profile: 'TrueyeMob',
          access_token: connectionDetails.authToken,
          id_token: null,
          location_id: connectionDetails.locationId,
        }
        this.send(Buffer.from(JSON.stringify(streamRequest)))
      }
      this.websocket.onmessage = (message) => {
        if (this.notYetPlaying) {
          this.notYetPlaying = false
          this.onStatusChange?.('PLAYING')
        }
        if (this.websocket?.readyState === WebSocket.OPEN) {
          if (connectionDetails?.imageRef?.current) {
            let blob = new Blob([new Uint8Array(message.data)])
            connectionDetails.imageRef.current.src = URL.createObjectURL(blob)
          }
        }
      }
      this.websocket.onerror = () => {
        if (this.onStatusChange) {
          this.onStatusChange('ERROR')
        }
      }

      this.websocket.onclose = () => {
        if (this.onStatusChange) {
          this.onStatusChange('ERROR')
        }
      }
    } else {
      this.websocket = null
      console.log('Websockets are not supported by this browser.')
    }
  }

  close() {
    try {
      if (this.websocket) {
        this.websocket.close(1000)
      }
    } catch (error) {
      console.log(error)
    }
  }
}
