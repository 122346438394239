import { useEffect } from 'react'
import '@enterprise-ui/canvas-ui-css'
import Header from './components/Header'
import { Helmet } from 'react-helmet'
import { useState } from 'react'
import { ChevronLeftIcon, MenuIcon } from '@enterprise-ui/icons'
import WorkZone from './pages/WorkZone'
import { useAuth } from '@praxis/component-auth'
import { ProtectedElement } from '@praxis/component-auth'
import { QueryClient, QueryClientProvider } from 'react-query'
import { QueryDefaults } from 'trueye-component-library'
import './index.css'
import _ from 'lodash'
import useMyTVS, { CAMERA_TREE, FAVORITES_MODE, MONITOR_MODE } from './useMyTvs'
import { CameraMap } from './pages/WorkZone/hooks/useWorkzone'
import { getADgroupsAppList, hasAccessToRBAC } from './services/adGroupList'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: QueryDefaults.STALE_TIME,
      cacheTime: QueryDefaults.CACHE_TIME,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  },
})

const Main = () => {
  const auth = useAuth()
  const [adGroups, setAdGroups] = useState<any[]>()
  auth.session?.validate()
  // eslint-disable-next-line
  const [hideAccess] = useState<boolean>(
    !hasAccessToRBAC(auth.session?.userInfo?.memberOf ?? []),
  )
  const isAdmin =
    auth.session?.userInfo?.memberOf.some((group: string) => {
      return group.toLowerCase() === 'APP-OAUTH2-TRUEYE-ADMIN'.toLowerCase()
    }) || false

  const lanId = auth.session?.userInfo?.lanId ?? '' // TODO reconsider how we init this, and it's corresponding typing
  const authToken = auth.session?.userInfo?.accessToken ?? ''
  const userLegalName = auth.session?.userInfo?.fullName ?? ''
  const userEmail = auth.session?.userInfo?.email ?? ''
  const sessionId = _.uniqueId()
  const {
    createFavoriteOpen,
    closeCreateFavorite,
    currentMode,
    openCreateFavorite,
    setCurrentMode,
    setVideoIsPlaying,
    videoIsPlaying,
    cameraDrawerOpen,
    setCameraDrawerOpen,
    clearAllCameras,
    addCameras,
    removeCamera,
    selectedCameras,
    playFavoriteOnGrid,
    currentPlayingFavorite,
  } = useMyTVS()

  useEffect(() => {
    getADgroupsAppList(10)
      .then((response: any) => {
        setAdGroups(response.data.adgroups)
      })
      .catch(() => {
        setAdGroups([])
      })
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <ProtectedElement allowed={adGroups}>
        <Helmet defaultTitle="MyTVS 3.0" titleTemplate="%s - MyTVS 3.0" />
        <Header
          hasFeature={
            currentMode === MONITOR_MODE || currentMode === FAVORITES_MODE
          }
          headerIcon={cameraDrawerOpen ? ChevronLeftIcon : MenuIcon}
          featureOpen={cameraDrawerOpen}
          headerText={cameraDrawerOpen ? CAMERA_TREE : currentMode}
          onIconClick={() => setCameraDrawerOpen((val) => !val)}
          onCreateFavorite={openCreateFavorite}
          showCreateFavorite={!createFavoriteOpen && videoIsPlaying}
          clearAllCameras={clearAllCameras}
        />
        <WorkZone
          cameraDrawerOpen={cameraDrawerOpen}
          currentMode={currentMode}
          lanId={lanId}
          onLogout={auth.logout}
          setCameraDrawerOpen={setCameraDrawerOpen}
          setCurrentMode={setCurrentMode}
          sessionId={sessionId}
          authToken={authToken}
          userLegalName={userLegalName}
          createFavoriteOpen={createFavoriteOpen}
          onCloseCreateFavorite={closeCreateFavorite}
          setVideoIsPlaying={(cameras: CameraMap) =>
            setVideoIsPlaying(!!cameras.size)
          }
          hideAccess={hideAccess}
          userEmail={userEmail}
          isAdmin={isAdmin}
          addCameras={addCameras}
          removeCamera={removeCamera}
          selectedCameras={selectedCameras}
          playFavoriteOnGrid={playFavoriteOnGrid}
          clearFavorite={clearAllCameras}
          currentPlayingFavorite={currentPlayingFavorite}
        />
      </ProtectedElement>
    </QueryClientProvider>
  )
}

export default Main
