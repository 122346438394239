import { styled } from 'styled-components'

export const VideoCardBox = styled.div`
  height: 289px;
  width: 336px;
  border: 1px;
  border-color: #6b6b6b;
  border-style: solid;
`
export const VideoTitle = styled.div`
  align-items: center;
  background: #1e1e1e;
  color: white;
  display: flex;
  gap: 8px;
  height: 32px;
  padding: 6px 8px;

  .video-title-icon {
    fill: #bdbdbd;
  }

  .video-title-online-icon {
    fill: #28ff4b;
  }

  .video-title-offline-icon {
    fill: #ff8266;
  }

  span {
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & :last-child {
    margin-left: auto;
  }
`

export const VideoBaseBox = styled.div`
  height: 255px;
`

export const VideoImage = styled.img`
  height: 100%;
  width: 100%;
`

export const VideoBoxMessage = styled.div`
  align-items: center;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  height: 100%;
`

export const VideoMessageText = styled.div`
  color: #ffffff;
`
