import Axios from 'axios'
import _ from 'lodash'
import { QueryFunctionContext } from 'react-query'

export const getContentByIdQueryKey = (key: string, url: string) => {
  return [key, url]
}

export async function fetchData({ queryKey }: QueryFunctionContext) {
  // eslint-disable-next-line
  const [key, url] = queryKey as ReturnType<typeof getContentByIdQueryKey>
  if (!url || !key) {
    return { data: [] }
  }

  const response = await Axios.get(url)
  const { data } = response

  return data
}

export const postContentByIdQueryKey = (
  key: string,
  url: string,
  req: object,
) => {
  return [key, url, req]
}

export async function postData({ queryKey }: QueryFunctionContext) {
  // eslint-disable-next-line
  const [key, url, req] = queryKey as ReturnType<typeof postContentByIdQueryKey>
  if (!url || !key || typeof url !== 'string') {
    return { data: [] }
  }

  const response = await Axios.post(url, req)
  const { data } = response

  return data
}

export async function fetchRegisterData({ queryKey }: QueryFunctionContext) {
  // eslint-disable-next-line
  const [key, url] = queryKey as ReturnType<typeof getContentByIdQueryKey>
  if (!url || !key) {
    return { data: [] }
  }
  const response = await Axios.get(url)
  let { data } = response
  data = _.groupBy(data, 'camera_id')
  return data
}

const defaults = {
  FETCH_ON_LOAD: true,
  STALE_TIME: 3600000, // 60 min
  CACHE_TIME: 24 * 60 * 60 * 1000, // 24 hours
}

export default defaults
