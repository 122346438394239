import styled from 'styled-components'
import { Button } from '@enterprise-ui/canvas-ui-react'

export const HEADER_HEIGHT = 56

export const HeaderItems = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;

  .close-button {
    margin-left: auto;
  }
`

export const StyledHeader = styled.header`
  background-color: #333;
  color: #fff;
  display: flex;
  height: ${HEADER_HEIGHT}px; // can potentially add this as a variable since the camera drawer will be anchored below this.
  min-height: ${HEADER_HEIGHT}px;
  padding: 16px;
  position: sticky;
  top: 0;
`

export const ModeName = styled.h4`
  font-size: 16px;
`

export const CameraTreeButton = styled(Button)`
  height: 25px;
  width: 25px;
`

export const HeaderButtonContainer = styled.div`
  margin-left: auto;
`

export const HeaderButton = styled(Button)`
  color: white;
  gap: 5px;
  &.\--type-ghost {
    color: #90caf9;
  }
`
