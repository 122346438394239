import EnterpriseIcon, { LogoutIcon } from '@enterprise-ui/icons'
import {
  ProfileActionButton,
  ProfileActionContainer,
  ProfileActionItem,
  ProfileContent,
  UserAvatarContainer,
  UserInitials,
  UserPreferredName,
} from './styles'

interface ProfileProps {
  onLogoutClick: Function
  userLegalName: string
}

const Profile = ({ userLegalName, onLogoutClick }: ProfileProps) => {
  const splitUserFirstLastName = userLegalName.split(' ')
  const userFirstName = splitUserFirstLastName[0]
  const userLastName = splitUserFirstLastName[1]
  return (
    <ProfileContent>
      <UserAvatarContainer>
        <UserInitials>{`${userFirstName[0]}${userLastName[0]}`}</UserInitials>
      </UserAvatarContainer>
      <UserPreferredName>{userLegalName}</UserPreferredName>
      <ProfileActionContainer>
        {/* <ProfileActionItem>
          <ProfileActionButton iconOnly aria-label={'app information'}>
            <EnterpriseIcon icon={InfoIcon} />
          </ProfileActionButton>
          About
        </ProfileActionItem> */}
        <ProfileActionItem onClick={() => onLogoutClick()}>
          <ProfileActionButton iconOnly aria-label={'logout of app'}>
            <EnterpriseIcon icon={LogoutIcon} />
          </ProfileActionButton>
          Logout
        </ProfileActionItem>
      </ProfileActionContainer>
    </ProfileContent>
  )
}

export default Profile
