import { useState } from 'react'
import { Layout } from 'trueye-component-library'
import CameraTreeItem from 'trueye-component-library/dist/models/camera/CameraTreeItem'

export const MONITOR_MODE = 'Monitor'
export const RBAC_MODE = 'Access'
export const PROFILE_MODE = 'Profile'
export const FAVORITES_MODE = 'Favorites'
export const CAMERA_TREE = 'Camera Tree'

export type AppMode =
  | typeof MONITOR_MODE
  | typeof RBAC_MODE
  | typeof PROFILE_MODE
  | typeof FAVORITES_MODE

export type CameraID = string
export type CameraMap = Map<CameraID, CameraTreeItem>

export default function useMyTvs() {
  const [cameraDrawerOpen, setCameraDrawerOpen] = useState<boolean>(false)
  const [currentMode, setCurrentMode] = useState<AppMode>(MONITOR_MODE)
  const [createFavoriteOpen, setCreateFavoriteOpen] = useState<boolean>(false)
  const [videoIsPlaying, setVideoIsPlaying] = useState<boolean>(false)
  const [selectedCameras, setSelectedCameras] = useState<CameraMap>(new Map())
  const [currentPlayingFavorite, setCurrentPlayingFavorite] =
    useState<Layout | null>(null)

  function addCameras(cameras: CameraMap) {
    const newSelectedCameras = new Map(selectedCameras)
    for (let camera of cameras.values()) {
      newSelectedCameras.set(camera.id, camera)
    }
    setSelectedCameras(newSelectedCameras)
    setVideoIsPlaying(true)
  }

  function removeCamera(cameraId: string) {
    const newSelectedCameras = new Map(selectedCameras)
    newSelectedCameras.delete(cameraId)
    setSelectedCameras(newSelectedCameras)
    if (newSelectedCameras.size === 0) {
      setVideoIsPlaying(false)
    }
  }

  function clearAllCameras() {
    setSelectedCameras(new Map())
    setVideoIsPlaying(false)
    setCurrentPlayingFavorite(null)
  }

  function playFavoriteOnGrid(layout: Layout | null) {
    setCurrentPlayingFavorite(layout)
    const newCameraMap = new Map()
    layout?.layout.forEach((camera) => {
      newCameraMap.set(camera.id, camera)
    })
    setSelectedCameras(newCameraMap)
    setVideoIsPlaying(true)
  }

  function openCreateFavorite() {
    setCreateFavoriteOpen(true)
  }
  function closeCreateFavorite() {
    setCreateFavoriteOpen(false)
  }
  return {
    currentMode,
    closeCreateFavorite,
    createFavoriteOpen,
    openCreateFavorite,
    setCurrentMode,
    setVideoIsPlaying,
    videoIsPlaying,
    cameraDrawerOpen,
    setCameraDrawerOpen,
    addCameras,
    removeCamera,
    clearAllCameras,
    selectedCameras,
    playFavoriteOnGrid,
    currentPlayingFavorite,
  }
}
