import { styled } from 'styled-components'

export const FavoritesContent = styled.div`
  background: #333333;
  height: 100%;
`

interface FavoriteFolderHeaderProps {
  $isOpen: boolean
}

export const FavoriteFolderHeader = styled.div<FavoriteFolderHeaderProps>`
  ${({ $isOpen }) =>
    $isOpen
      ? `
      background: #222222;
    `
      : `
    background: #2e2e2e;
    `}

  color: #bdbdbd;
  display: flex;
  gap: 10px;
  height: 48px;
  padding: 16px;
`

interface FavoriteItemContainerProps {
  $isSelected: boolean
}

export const FavoriteItemContainer = styled.div<FavoriteItemContainerProps>`
  align-items: center;
  background: #222222;
  color: #bdbdbd;
  display: flex;
  gap: 12px;
  height: 48px;
  padding-right: 16px;

  ${(props) => {
    if (props.$isSelected) {
      return `
        background: #293038;
        .indicator {
          background: #42a5f5;
        }
      `
    }
  }}
`

export const FavoriteSelectedIndicator = styled.div`
  // background: #42a5f5;
  background: transparent;
  min-width: 6px;
  height: 100%;
`
export const FavoriteIconContainer = styled.div`
  min-width: 20px;
`

export const FavoriteItemTextBox = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`
