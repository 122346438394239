import {
  RBACBox,
  Pair,
  Heading,
  Value,
  Buttons,
  Reject,
  Approve,
} from './styles'
import EnterpriseIcon, { CancelIcon, CheckIcon } from '@enterprise-ui/icons'

interface CardProps {
  onApprove: Function
  onReject: Function
  lanId: string
  requestData: any
}

const RBACCard = ({ onApprove, onReject, requestData }: CardProps) => {
  return (
    <RBACBox>
      <Pair>
        <Heading>Requested By</Heading>
        <Value>{requestData.user_fullname}</Value>
      </Pair>
      <Pair>
        <Heading>Role</Heading>
        <Value>{requestData.user_role}</Value>
      </Pair>
      <Pair>
        <Heading>Location</Heading>
        <Value>{requestData.location_to_access_name}</Value>
      </Pair>
      <Pair>
        <Heading>Start Date</Heading>
        <Value>
          {new Date(requestData.start_date_access).toLocaleDateString('en-US')}
        </Value>
      </Pair>
      <Pair>
        <Heading>End Date</Heading>
        <Value>
          {new Date(requestData.end_date_access).toLocaleDateString('en-US')}
        </Value>
      </Pair>
      <Pair>
        <Heading>Reason for Request</Heading>
        <Value>{requestData.reason}</Value>
      </Pair>

      <Buttons>
        <Reject
          onClick={() => {
            onReject()
          }}
        >
          <EnterpriseIcon icon={CancelIcon} /> Reject
        </Reject>
        <Approve
          onClick={() => {
            onApprove()
          }}
        >
          <EnterpriseIcon icon={CheckIcon} /> Approve
        </Approve>
      </Buttons>
    </RBACBox>
  )
}

export default RBACCard
