import { FavoritesContent, FavoriteSelectContainer } from './styles'
import { Layout, StreamingSpinner } from 'trueye-component-library'
import {
  CameraActionButton,
  CameraActionContainer,
} from '../../components/CameraTree/styles'
import { Button, ButtonGroup } from '@enterprise-ui/canvas-ui-react'
import useFavorites from './hooks/useFavorites'
import NothingToShowMessage from '../../components/NothingToShowMessage'
import {
  FavoriteIconContainer,
  FavoriteItemContainer,
  FavoriteItemTextBox,
  FavoriteSelectedIndicator,
} from '../../components/FavoriteFolder/styles'
import EnterpriseIcon, { ThumbsUpIcon, TrashIcon } from '@enterprise-ui/icons'
import ActionModal from '../../components/ActionModal'

interface FavoritesProps {
  lanId: string
  onShowFavoriteClick: (layout: Layout | null) => void
  onClearClick: () => void
  playingLayout: Layout | null
}
const Favorites = ({
  lanId,
  onShowFavoriteClick,
  onClearClick,
  playingLayout,
}: FavoritesProps) => {
  const {
    isError,
    isFetching,
    isSuccess,
    privateLayouts,
    publicLayouts,
    setWaitlistedLayout,
    waitlistedLayout,
    modalOpen,
    promptDelete,
    cancelDelete,
    deleteFavorite,
  } = useFavorites(lanId, playingLayout) // TODO figure out how we can get a local selection of the layout before we click "Show selected"

  if (isFetching) {
    return <StreamingSpinner message="Loading favorites..." />
  }

  if (isSuccess) {
    if (publicLayouts.length <= 0 && privateLayouts.length <= 0) {
      return (
        <FavoritesContent>
          <NothingToShowMessage text="You have no favorites yet" />
        </FavoritesContent>
      )
    }
    return (
      <FavoritesContent>
        {modalOpen && (
          <ActionModal
            isNegativeAction
            isVisible={modalOpen}
            onCancel={cancelDelete}
            onPrimaryAction={() => {
              deleteFavorite()
            }}
            prompt="Are you sure you want to delete this favorite?"
            actionButtonText="Delete"
          />
        )}
        <FavoriteSelectContainer>
          {/* 
          We may need this in the future in case we allow private/public folders in MyTVS
          
          <FavoriteFolder
            favorites={publicLayouts}
            folderName="Public"
            isPublic
            onFavoriteClick={setWaitlistedLayout}
            selectedLayout={waitlistedLayout}
          /> 
          <FavoriteFolder
            favorites={privateLayouts}
            folderName="Private"
            isPublic={false}
            onFavoriteClick={setWaitlistedLayout}
            selectedLayout={waitlistedLayout}
          />
          */}
          {privateLayouts.map((favorite: Layout) => (
            <FavoriteItemContainer
              key={favorite.gl_id}
              $isSelected={waitlistedLayout?.gl_id === favorite.gl_id}
              onClick={() => setWaitlistedLayout(favorite)}
            >
              <FavoriteSelectedIndicator className="indicator" />
              <FavoriteIconContainer>
                <EnterpriseIcon icon={ThumbsUpIcon} />
              </FavoriteIconContainer>
              <FavoriteItemTextBox>{favorite.layoutName}</FavoriteItemTextBox>
              <Button onClick={() => promptDelete(favorite.gl_id)} type="ghost">
                <EnterpriseIcon icon={TrashIcon} />
              </Button>
            </FavoriteItemContainer>
          ))}
        </FavoriteSelectContainer>
        <CameraActionContainer>
          <ButtonGroup>
            <CameraActionButton
              disabled={
                (waitlistedLayout === null ||
                  Object.keys(waitlistedLayout).length === 0) &&
                playingLayout === null
              }
              fullWidth
              onClick={() => {
                setWaitlistedLayout(null)
                if (playingLayout !== null) {
                  onClearClick()
                }
              }}
              type="secondary"
            >
              Clear
            </CameraActionButton>
            <CameraActionButton
              disabled={
                waitlistedLayout === null ||
                Object.keys(waitlistedLayout).length === 0
              }
              fullWidth
              type="primary"
              onClick={() => {
                onShowFavoriteClick(waitlistedLayout)
              }}
            >
              Show Selected
            </CameraActionButton>
          </ButtonGroup>
        </CameraActionContainer>
      </FavoritesContent>
    )
  }

  if (isError) {
    return <p>There was an issue fetching layouts</p>
  }
}

export default Favorites
