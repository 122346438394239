import EnterpriseIcon from '@enterprise-ui/icons'
import { ButtonGroup } from '@enterprise-ui/canvas-ui-react'
import {
  CameraActionButton,
  CameraActionContainer,
  CameraSelectContainer,
  CameraTreeContainer,
  CameraSeachBar,
  CameraSearchContainer,
  CameraSearchIcon,
} from './styles'

import { LocationHeader } from '../LocationHeader'
import { SearchIcon } from '@enterprise-ui/icons'
import { CameraFolder } from '../CameraFolder'
import { CameraItem } from '../CameraItem'

import useCameraTree from './hooks/useCameraTree'
// import { CameraMap } from '../WorkZone/hooks/useWorkzone'
import { CameraMap } from '../../pages/WorkZone/hooks/useWorkzone'

import { findInSearchPath, StreamingSpinner } from 'trueye-component-library'
import Location from 'trueye-component-library/dist/models/location/Location'

import CameraTreeItem from 'trueye-component-library/dist/models/camera/CameraTreeItem'

interface CameraTreeProps {
  addCameras: Function
  onChangeLocation: Function
  openLocationData: Location
  selectedCameras: CameraMap
}

export const CameraTree = ({
  addCameras,
  onChangeLocation,
  openLocationData,
  selectedCameras,
}: CameraTreeProps) => {
  const {
    addCameraToWaitlist,
    cameraTreeData,
    cameraWaitlist,
    clearCameraWaitlist,
    locationTId,
    onChangeSearchInput,
    searchInput,
    isError,
    isFetching,
    isSuccess,
  } = useCameraTree(selectedCameras, openLocationData)

  if (isFetching) {
    return <StreamingSpinner message="Loading cameras..." />
  }

  if (isSuccess) {
    return (
      <div>
        <CameraSelectContainer>
          <LocationHeader
            changeLocationClick={() => onChangeLocation()}
            locationId={locationTId}
            locationName={openLocationData.locationName}
          />
          <CameraTreeContainer>
            <CameraSearchContainer>
              <CameraSeachBar
                placeholder="Search Cameras"
                onChange={onChangeSearchInput}
              />
              <CameraSearchIcon>
                <EnterpriseIcon icon={SearchIcon} />
              </CameraSearchIcon>
            </CameraSearchContainer>
            {cameraTreeData[0]?.children &&
              cameraTreeData[0]?.children[0]?.children?.map(
                (child: CameraTreeItem) => {
                  const isSearchingForCamera = !!searchInput
                  const isChildVisible = isSearchingForCamera
                    ? findInSearchPath(child, searchInput)
                    : true
                  if (!isChildVisible) {
                    return null
                  }

                  if (child?.children && !child?.macid) {
                    return (
                      <CameraFolder
                        key={child.id}
                        cameraWaitlist={cameraWaitlist}
                        folderData={child}
                        onCameraClick={addCameraToWaitlist}
                        searchInput={searchInput}
                      />
                    )
                  } else {
                    return (
                      <CameraItem
                        key={child.id}
                        cameraName={child.name}
                        isChecked={cameraWaitlist.has(child.id)}
                        onCameraClick={() => {
                          addCameraToWaitlist(child)
                        }}
                      />
                    )
                  }
                },
              )}
          </CameraTreeContainer>
        </CameraSelectContainer>
        <CameraActionContainer>
          <ButtonGroup>
            <CameraActionButton
              fullWidth
              onClick={clearCameraWaitlist}
              type="secondary"
            >
              Clear Cameras
            </CameraActionButton>
            <CameraActionButton
              fullWidth
              type="primary"
              onClick={() => {
                addCameras(cameraWaitlist)
              }}
            >
              Add Selected Cameras
            </CameraActionButton>
          </ButtonGroup>
        </CameraActionContainer>
      </div>
    )
  }

  if (isError) {
    return (
      <div>
        <CameraSelectContainer>
          <LocationHeader
            changeLocationClick={() => onChangeLocation()}
            locationId={locationTId}
            locationName={openLocationData.locationName}
          />
          <p>There was an error loading the cameras. Please try again.</p>
        </CameraSelectContainer>
      </div>
    )
  }
}
