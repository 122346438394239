import styled from 'styled-components'

interface CameraFolderHeaderProps {
  $isOpen: boolean
}

export const CameraFolderHeader = styled.div<CameraFolderHeaderProps>`
  background: ${(props) => (props.$isOpen ? '#2e2e2e' : '#333333')};
  display: flex;
  gap: 10px;
  height: 48px;
  padding: 16px;
`

export const FolderTextBox = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  color: #bdbdbd;
`
