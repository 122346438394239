import { ChangeEvent, useEffect, useState } from 'react'
// import { CameraID, CameraMap } from '../../WorkZone/hooks/useWorkzone'
import { CameraID, CameraMap } from '../../../pages/WorkZone/hooks/useWorkzone'

import { generateTId, useCameraTreeQuery } from 'trueye-component-library'
import Location from 'trueye-component-library/dist/models/location/Location'
import CameraTreeItem from 'trueye-component-library/dist/models/camera/CameraTreeItem'
import apiConfig from '../../../apiConfig'

export default function useCameraTree(
  selectedCameras: CameraMap,
  locationData: Location,
) {
  const [searchInput, setSearchInput] = useState<string>('')
  const [cameraWaitlist, setCameraWaitlist] = useState<CameraMap>(new Map())

  const locationTId = generateTId(locationData.locationId)
  const {
    data: cameraTreeData = [],
    isFetching,
    isError,
    isSuccess,
  } = useCameraTreeQuery(
    `${apiConfig.camera?.cameraTreeService}?id=${locationTId}`,
  )

  useEffect(() => {
    const initCameraWaitlist = new Map<CameraID, CameraTreeItem>()
    getCheckedCameras(cameraTreeData[0], selectedCameras, initCameraWaitlist)
    setCameraWaitlist(initCameraWaitlist)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function onChangeSearchInput(event: ChangeEvent<HTMLInputElement>) {
    setSearchInput(event.target.value)
  }

  function getCheckedCameras(
    cameraData: CameraTreeItem,
    selectedCameras: CameraMap,
    initCameraWaitlist: CameraMap,
  ) {
    if (!cameraData?.children || cameraData?.macid) {
      if (selectedCameras.has(cameraData?.id)) {
        initCameraWaitlist.set(cameraData.id, cameraData)
      }
    } else {
      cameraData.children.forEach((children: CameraTreeItem) => {
        getCheckedCameras(children, selectedCameras, initCameraWaitlist)
      })
    }
  }

  function addCameraToWaitlist(camera: CameraTreeItem) {
    const newWaitlist = new Map(cameraWaitlist)
    if (cameraWaitlist.has(camera.id)) {
      newWaitlist.delete(camera.id)
    } else {
      newWaitlist.set(camera.id, camera)
    }
    setCameraWaitlist(newWaitlist)
  }

  function clearCameraWaitlist() {
    setCameraWaitlist(new Map())
  }

  return {
    addCameraToWaitlist,
    cameraTreeData,
    cameraWaitlist,
    clearCameraWaitlist,
    isError,
    isFetching,
    isSuccess,
    locationTId,
    onChangeSearchInput,
    searchInput,
  }
}
