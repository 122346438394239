import { AuthProvider } from '@praxis/component-auth'
import Main from './Main'
import apiConfig from './apiConfig'

const App = () => {
  return (
    <AuthProvider
      shouldSendHeaders={() => true}
      {...apiConfig.auth}
      clientId={apiConfig.auth?.clientId ?? ''}
    >
      <Main />
    </AuthProvider>
  )
}
export default App
