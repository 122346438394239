import styled from 'styled-components'
import { Modal } from '@enterprise-ui/canvas-ui-react'

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100px;
  max-width: 360px;
  width: 100%;
  padding: 16px;
  background: #272727;
  border: 0.5px;
  border-color: #6b6b6b;
  border-style: solid;
}
`

export const Heading = styled.header`
  color: #eeeeee;
  font-size: 13px;
  font-weight: normal;
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
`

export const TextBox = styled.textarea`
  width: 300px;
  height: 100px;
  padding: 8px;
  border: 0.5px;
  border-color: #eeeeee;
  border-style: solid;
  border-radius: 5px;
  background: transparent;
  color: #eeeeee;
  font-size: 11px;
  resize: none;

  &:focus {
    outline: none;
  }
`

export const CharacterCounter = styled.span`
  color: #a1a1a1;
  font-size: 11px;
`

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  width: 300px;
`

export const Cancel = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  color: #a1a1a1;
  padding: 8px 18px;
  text-align: center;
  text-color: #f8a3a0;
  font-size: 14px;
  font-weight: 500;

  &:active {
    color: lightblue;
  }
`

export const ActionButton = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  background-color: #5def79;
  border-radius: 5px;
  color: #000000;
  padding: 8px 18px;
  text-align: center;
  text-color: #f8a3a0;
  font-size: 14px;
  font-weight: 500;

  &:active {
    background-color: #3ce65b;
    box-shadow: 0px 0px 1px 1px #3ce65b;
  }

  &.\--negative-action {
    background-color: #f8a3a0;

    &:active {
      background-color: #ff7a7a;
      box-shadow: 0px 0px 1px 1px #ff7a7a;
    }
  }
`

export const CustomModal = styled(Modal)`
  display: flex;
  flex-direction: column;

  .C-Button {
    display: none;
  }

  .C-Modal__container {
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: column;
    min-height: 200px;
    max-width: 360px;
    width: 100%;
    padding: 16px;
    align-items: center;
    box-shadow: none;
  }
`
