import { useState } from 'react'
import { CameraMap } from '../../../pages/WorkZone/hooks/useWorkzone'
import apiConfig from '../../../apiConfig'
import axios from 'axios'
import { useQueryClient } from 'react-query'
import { ToasterToastProps, useToaster } from '@enterprise-ui/canvas-ui-react'

const MYTVS_APP_NAME = 'myTvs'

export default function useCreateFavorite(lanId: string) {
  const [layoutName, setLayoutName] = useState<string>('')
  const queryClient = useQueryClient()

  const makeToast = useToaster()
  const toastSuccessConfig = {
    className: 'access-toaster',
    type: 'success',
    heading: 'Success',
  }

  const toastErrorConfig = {
    className: 'access-toaster',
    type: 'error',
    heading: 'Something went wrong',
    message: 'Please try again',
  }

  function saveNewFavorite(lanId: string, layout: any, layoutName: string) {
    return axios.post(`${apiConfig?.camera?.layout}`, {
      id: lanId,
      location: MYTVS_APP_NAME,
      layout,
      layoutName,
    })
  }

  function makeSaveLayoutRequest(layoutName: string, cameras: CameraMap) {
    const selectedCamerasArray = Array.from(cameras.values())
    const layoutArray = selectedCamerasArray.map((cameraData, index) => {
      return {
        i: `n${index}`,
        w: 1,
        h: 1,
        id: cameraData.id,
        name: cameraData.name,
        type: cameraData.camera_type,
        is_ptz: cameraData.is_ptz,
        ishover: false,
        storeName: 'T7827 - Store Labs Moon',
        x: index % 4,
        y: index,
      }
    })
    saveNewFavorite(lanId, layoutArray, layoutName)
      .then(() => {
        queryClient.invalidateQueries('layouts')
        makeToast({
          ...toastSuccessConfig,
          message: 'Layout created',
        } as ToasterToastProps)
      })
      .catch(() => {
        makeToast({ ...toastErrorConfig } as ToasterToastProps)
      })
  }

  return {
    layoutName,
    makeSaveLayoutRequest,
    setLayoutName,
  }
}
