import { Button } from '@enterprise-ui/canvas-ui-react'
import styled from 'styled-components'

export const LOCATION_BANNER_HEIGHT = 99
export const LocationBanner = styled.div`
  background: #2e2e2e;
  display: flex;
  flex-direction: column;
  height: ${LOCATION_BANNER_HEIGHT}px;
  padding: 16px;
`

export const LocationInfoContainer = styled.div`
  display: flex;
  gap: 10px;
`
export const CloseFunctionButton = styled(Button)`
  margin-left: auto;
  &.\--type-ghost {
    color: #90caf9;
  }
`
