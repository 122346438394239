import { styled } from 'styled-components'
import { HEADER_HEIGHT } from '../../components/Header/styles'
import { MODE_BUTTON_HEIGHT } from '../../components/ModeSelectBar/styles'

export const FeatureSpace = styled.div`
  height: calc(
    100dvh - ${HEADER_HEIGHT}px - ${MODE_BUTTON_HEIGHT}px
  ); // 136px is the combined value for the header and the button group
  background-color: #000;
  overflow: auto;
`
