import axios from 'axios'
import apiConfig from '../apiConfig'

const ADGROUPS_WITH_RBAC_ACCESS = [
  'app-oauth2-mytvs-usr',
  'app-oauth2-mytvs-admn',
  'app-oauth2-trueye-admin',
]

export const getADgroupsAppList = (appId: number) => {
  return axios.get(apiConfig.rbac?.adGroup + `?application_id=${appId}`, {
    timeout: 7000,
  })
}

export const hasAccessToRBAC = (userAdGroups: string[]) => {
  const adgroupsWithAccess = new Set(ADGROUPS_WITH_RBAC_ACCESS)

  for (const userAdGroup of userAdGroups) {
    if (adgroupsWithAccess.has(userAdGroup.toLowerCase())) {
      return true
    }
  }

  return false
}
