import { memo } from 'react'
import {
  VideoBaseBox,
  VideoBoxMessage,
  VideoCardBox,
  VideoImage,
  VideoMessageText,
  VideoTitle,
} from './styles'
import EnterpriseIcon, { CancelIcon } from '@enterprise-ui/icons'
import { FiberManualRecord } from '@material-ui/icons'
import { StreamingSpinner } from 'trueye-component-library'

import CameraTreeItem from 'trueye-component-library/dist/models/camera/CameraTreeItem'
import useVideoCard from './hooks/useVideoCard'
import CameraOfflineIcon from './Icons/cameraOfflineIcon.svg'
import CameraIssueIcon from './Icons/cameraIssueIcon.svg'

interface VideoCardProps {
  //TODO Reconsider prop structure and naming for this component
  cameraData: CameraTreeItem
  lanId: string
  authToken: string
  removeCamera: Function
  sessionId: string
}

const VideoCard = ({
  lanId,
  authToken,
  cameraData,
  removeCamera,
  sessionId,
}: VideoCardProps) => {
  // TODO - Create a useVideoCard hook so that we can fetch the camera data for the streaming
  // Needed because there are some details we need for decoding the video

  const { data, isFetching, imageRef, videoStatus } = useVideoCard(
    authToken,
    cameraData,
  )

  if (isFetching || videoStatus === 'CONNECTING') {
    return (
      <VideoCardBox>
        <VideoTitle>
          <FiberManualRecord
            classes={{
              root: 'video-title-icon',
            }}
            fontSize="inherit"
          />{' '}
          {cameraData.name}
          <EnterpriseIcon
            icon={CancelIcon}
            onClick={() => removeCamera(cameraData.id)}
          />
        </VideoTitle>
        <VideoBaseBox>
          <StreamingSpinner message="Loading Camera" />
        </VideoBaseBox>
      </VideoCardBox>
    )
  }

  if (data?.length) {
    const camera = data[0]
    return (
      <VideoCardBox>
        <VideoTitle>
          <FiberManualRecord
            classes={{
              root:
                camera.camera_condition === 'online' &&
                videoStatus === 'PLAYING'
                  ? 'video-title-online-icon'
                  : 'video-title-offline-icon',
            }}
            fontSize="inherit"
          />{' '}
          <span>{`${camera.location_id} - ${cameraData.name}`}</span>
          <EnterpriseIcon
            icon={CancelIcon}
            onClick={() => removeCamera(camera.id)}
          />
        </VideoTitle>
        <VideoBaseBox>
          {camera.camera_condition === 'online' ? (
            videoStatus === 'PLAYING' ? (
              <VideoImage ref={imageRef} />
            ) : (
              <VideoBoxMessage>
                <CameraIssueIcon />
                <VideoMessageText>Issue Connecting to Camera</VideoMessageText>
              </VideoBoxMessage>
            )
          ) : (
            <VideoBoxMessage>
              <CameraOfflineIcon />
              <VideoMessageText>Camera Offline</VideoMessageText>
            </VideoBoxMessage>
          )}
        </VideoBaseBox>
      </VideoCardBox>
    )
  }
}

export default memo(
  VideoCard,
  (prevProps: VideoCardProps, nextProps: VideoCardProps) => {
    return prevProps === nextProps
  },
)
